<template>
  <div v-if="devicesData.length > 0">
    <v-row class="ma-0" align="center">
      <div
        :style="loading ? 'pointer-events: none!important' : ''"
        style="width: 100%"
      >
        <v-menu v-model="menu" nudge-top="2" offset-y>
          <template v-slot:activator="{on, attrs}">
            <div
              class="tower-selector pl-2"
              v-bind="attrs"
              v-on="on"
              style="width: 100%"
            >
              <v-hover :disabled="loading" v-slot="{hover}">
                <div
                  class="mt-2 d-flex justify-space-between align-center"
                  style="width: 15rem"
                >
                  <div class="d-flex justify-center align-center">
                    <span
                      class="font-weight-medium mx-2"
                      style="font-size: 14px; color: rgba(0, 0, 0, 0.6)"
                      >{{ towerId }} :
                    </span>
                    <span
                      class="font-weight-bold"
                      style="
                        font-size: 14px;
                        user-select: none;
                        color: rgba(0, 0, 0, 0.8);
                      "
                    >
                      {{
                        devicesData[selectedTower].deviceName.replace(
                          /-NVR/gm,
                          '',
                        )
                      }}
                    </span>
                  </div>

                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    width="2"
                    size="16"
                    color="primary"
                    class="mr-4"
                  />
                  <v-icon
                    v-else
                    size="20"
                    class="mr-2"
                    :class="hover ? 'primary--text' : ''"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </v-hover>
            </div>
          </template>

          <v-list nav style="width: 100%">
            <v-list-item-group v-model="selectedTower">
              <v-list-item
                v-for="(value, index) in devicesData"
                :key="value.deviceId"
                dense
                :ripple="false"
              >
                <v-list-item-title
                  class="ml-1"
                  :class="
                    selectedTower == index
                      ? 'primary--text font-weight-bold'
                      : ''
                  "
                  style="font-size: 15px"
                  >{{ value.deviceName.replace(/-NVR/gm, '') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </v-row>
  </div>
</template>

<script>
import {ref, watch, computed} from '@vue/composition-api';
import {useStore, useI18n} from '@/plugins/vueUseMethods';

export default {
  name: 'TowerSelector',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, {emit}) {
    const store = useStore();
    const menu = ref(false);
    const {$i18n} = useI18n();
    const selectedTower = ref(0);

    const towerId = computed(() => {
      if ($i18n.locale === 'de') {
        return 'Turm ID';
      } else {
        return 'Tower ID';
      }
    });

    const devicesData = computed(() => {
      const armingMaskDevicesData = store.getters.armingMaskDevicesData;
      if (armingMaskDevicesData) {
        return armingMaskDevicesData;
      }
      return [];
    });

    watch(selectedTower, () => {
      if (menu.value) {
        changeEvent();
      }
    });

    function changeEvent() {
      emit('update', devicesData.value[selectedTower.value].arming_data);
      store.commit(
        'updateProjectArmingMaskCurrentDevice',
        devicesData.value[selectedTower.value].arming_data,
      );
    }

    return {
      menu,
      selectedTower,
      devicesData,
      towerId,
    };
  },
};
</script>

<style scoped>
.tower-selector {
  height: 40px;
  width: 200px;
  background-color: #f0f0f0;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 200ms linear;
}
.tower-selector:hover {
  background-color: #e0e0e0;
  transition: background-color 200ms linear;
}
</style>
