<template>
  <div style="position: relative">
    
    <div class="gallery">
      <div
        v-for="(item, index) in dataSource"
        :key="'gallery_' + (index)"
      >
        <v-hover
          :disabled="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
          v-slot="{hover}"
        >
          <div
            class="item-gallery elevation-0"
          >
            <div
              style="
                border-radius: 5px !important;
                max-width: 100%;
                max-height: 100%;
              "
            >
              <TowerListViewCard
                :dataSource="item"
                :hover="hover"
                @export:canvasDataURL="getImageUrls"
              />
            </div>
          </div>
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from '@vue/composition-api'
// import Gallery from '@components/list/Gallery.vue';
import TowerListViewCard from '@/components/tools/project/alarm-areas/TowerListViewCard.vue';

export default {
  name: 'TowerListViewGallery',
  props: {
    latest: {
      required: true,
    },
  },

  components: {
    TowerListViewCard,
  },

  setup(props, {emit}) {
    // ========================================================================
    // Handle export image url that support for downloading
    // ========================================================================
    const imageUrls = ref([])
    function getImageUrls(canvasDataUrl) {
      if (canvasDataUrl) {
        imageUrls.value.push(canvasDataUrl)
      }
    }
    emit('export:imageUrls', imageUrls)

    // ========================================================================
    // Sample Data
    // ========================================================================
    const dataSource = ref([...props.latest])


    return {
      dataSource,
      getImageUrls,
    }
  }
};
</script>

<style>
.item-gallery {
  position: relative;
  background-color: rgba(230, 230, 230, 1);
  aspect-ratio: 16 / 9;
  border-radius: 5px !important;
  cursor: pointer;
}
.gallery {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); */
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  /* max-width: 100%; */

  margin-inline: auto;
  padding-inline: 1rem;
  padding-bottom: 10px;
}
</style>
