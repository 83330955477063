<template>
  <div class="gallery-image-container" ref="container">
    <!-- SHOW LOADING FOR IMAGE -->
    <div>

      <div v-if="loadingImage">
        <v-progress-circular
          class="loading-center"
          indeterminate
          width="3"
          size="30"
          color="primary"
        ></v-progress-circular>
      </div>

      <!-- SHOW ERROR IF LOADED IMAGE IS FAILED -->
      <div
        v-if="loadingImageError && !loadingImage"
        class="image-error"
      >
        <v-row class="ma-0" align="center">
          <v-icon color="black" size="20" class="mr-1">mdi-alert-circle</v-icon>
          <b style="font-size: 1.25em;">{{ $t('tools.project.dashboard.error.image') }}</b>
        </v-row>
      </div>
    </div>

    <!-- KONVA HANDLING -->
    <v-stage v-show="!loadingImage" :config="stageConfig" ref="stageRef">
      <v-layer>
        <!-- SHOW IMAGE -->
        <v-image :config="canvasBackground" ref="imageRef" />
      </v-layer>
      <v-layer>
        <v-group>
          <!-- SHOW POLYGON WHEN RESIZING THE BROWSER -->
          <v-line
            v-for="(value, key) in newSourcePoints"
            :key="`points-polygon-${key}`"
            :config="{
              ...polygonConfig,
              points: value,
              name: `polygon-${key}`,
            }"
          />
        </v-group>
      </v-layer>
    </v-stage>
  </div>
</template>

<script>
import {onMounted, ref, onUpdated, computed} from '@vue/composition-api';
import {useHelperPlugin} from '@/plugins/vueUseMethods';
import {useResizeObserver} from '@vueuse/core';

export default {
  props: {
    dataSource: {
      required: true,
    },
    index: {
      default: 0,
    },
    hover: {
      default: false,
    },
  },

  setup(props, {emit}) {
    const stageRef = ref(null);

    const stageConfig = ref({
      width: 0,
      height: 0,
    });

    onMounted(async () => {
      const wrapper = document.querySelector('.gallery-image-container');

      await loadImageBackground(wrapper);
      // set default size for each stage
      stageConfig.value.width = wrapper.offsetWidth;
      stageConfig.value.height = wrapper.offsetHeight;

      canvasBackground.value.width = wrapper.offsetWidth;
      canvasBackground.value.height = wrapper.offsetHeight;

      // Helpful for performance
      imageRef.value.getNode().cache();
    });

    onUpdated(() => {
      // Helpful for performance
      imageRef.value.getNode().cache();
      loadingImage.value = false;
      loadingImageError.value = true;
    });

    // ========================================================================
    // Resizing canvas with image when in responsive mode
    // ========================================================================
    const container = ref(null);

    useResizeObserver(container, (entries) => {
      const entry = entries[0];

      const {width, height} = entry.contentRect;

      stageConfig.value.width = width;
      stageConfig.value.height = height;

      canvasBackground.value.width = width;
      canvasBackground.value.height = height;

      makePointsChangeWhenCanvasResponsive(
        sourcePoints.value,
        newSourcePoints.value,
        width,
        height,
        imageSize.value.offsetWidth,
        imageSize.value.offsetHeight,
      );

      // // Helpful for performance
      imageRef.value.getNode().clearCache();
      loadingImage.value = true;
      loadingImageError.value = false;
    });

    function makePointsChangeWhenCanvasResponsive(
      source,
      target,
      canvasWidth,
      canvasHeight,
      imageWidth,
      imageHeight,
    ) {
      const clone = [...source];
      const widthRatio = Number((canvasWidth / imageWidth).toFixed(5));
      const heightRatio = Number((canvasHeight / imageHeight).toFixed(5));

      if (widthRatio === Infinity && heightRatio === Infinity) {
        return;
      }

      for (let i = 0; i < clone.length; i++) {
        const points = [];

        clone[i].forEach((p, index) => {
          if (index % 2 === 0) {
            p = Math.round(p * widthRatio);
          } else {
            p = Math.round(p * heightRatio);
          }
          points.push(p);
        });

        target[String(i)] = points;
      }
    }

    // ========================================================================
    // Handling loading image
    // ========================================================================
    const loadingImage = ref(true);
    const loadingImageError = ref(false);
    const imageRef = ref(null);
    const imageSize = ref({
      offsetWidth: 0,
      offsetHeight: 0,
    });

    const canvasBackground = ref({
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      fill: 'transparent',
      draggable: false,
      image: null,
      name: 'background-canvas',
    });

    async function exportUrlToBase64Image(imageUrl) {
      return useHelperPlugin().imageBase64(imageUrl, 'image/jpg');
    }

    async function loadImageBackground(wrapper) {
      const image = new window.Image();

      image.src = await exportUrlToBase64Image(props.dataSource.image.downloadUrl)

        image.onload = () => {
        loadingImage.value = false;

        canvasBackground.value.image = image;

        // Get real image size and assign it to "imageSize"
        imageSize.value.offsetWidth = image.width;
        imageSize.value.offsetHeight = image.height;

        // Get source points of polygons
        sourcePoints.value = getSourcePoints(
          props.dataSource.polygons,
          image.width,
          image.height,
        );

        makePointsChangeWhenCanvasResponsive(
          sourcePoints.value,
          newSourcePoints.value,
          wrapper.offsetWidth,
          wrapper.offsetHeight,
          imageSize.value.offsetWidth,
          imageSize.value.offsetHeight,
        );
      };
      image.onerror = (_1, _2, _3, _4, error) => {
        if (error === undefined) {
          loadingImageError.value = true;
        }
      }
    }

    // ========================================================================
    // Handling sourcePoints to draw polygon
    // ========================================================================
    const sourcePoints = ref([]);

    function getSourcePoints(polygons, imageWidth, imageHeight) {
      const polygonPoints = [];
      polygons.forEach((element) => {
        const convertedPoint = convertSourcePoints(
          element.points,
          imageWidth,
          imageHeight,
        );
        polygonPoints.push(convertedPoint);
      });
      return polygonPoints;
    }

    function convertSourcePoints(source, imageWidth, imageHeight) {
      const points = [];
      source.forEach((o) => {
        points.push(Math.round(o.x * imageWidth));
        points.push(Math.round(o.y * imageHeight));
      });
      return points;
    }

    const newSourcePoints = ref({});

    // ========================================================================
    // Styling Polygon
    // ========================================================================
    const defaultStyle = ref({
      strokeWidth: 4,
      stroke: 'red',
      lineJoin: 'round',
      lineCap: 'round',
      visible: true,
      closed: true,
      fill: 'rgba(250,0,0,0.2)',

      type: 'polygon',
    });

    const polygonConfig = ref({
      ...defaultStyle.value,
    });

    // ========================================================================
    // Export canvas url that support for downloading
    // ========================================================================
    const canvasDataURL = computed(() => {
      return stageRef.value.getNode().toDataURL({
        pixelRatio: 2,
      });
    });

    emit('export:canvasDataURL', canvasDataURL);

    return {
      stageRef,
      newSourcePoints,
      sourcePoints,
      polygonConfig,
      imageRef,
      container,
      stageConfig,
      loadingImage,
      loadingImageError,
      canvasBackground,
    };
  },
};
</script>

<style scoped>
.gallery-image-container {
  position: absolute;
  border-radius: 5px !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(220, 220, 220, 1);
  cursor: auto;
}
.gallery-alt-preset {
  position: absolute;
  bottom: 8px;
  left: 10px;
  z-index: 20;
  background-color: rgba(220, 220, 220, 0.9);
  border-radius: 12px;
  height: 27px;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
}
.gallery-alt-date {
  position: absolute;
  bottom: 8px;
  right: 10px;
  z-index: 20;
  background-color: rgba(220, 220, 220, 0.9);
  border-radius: 12px;
  height: 27px;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
}
.image-error {
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
