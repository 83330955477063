<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"
    :title="$t(`${$route.meta.i18n}.title`)"
    :error="!isLoading && failedCallApi"
    :loading="isLoading && !hasArmingMaskData"
    :isSettings="true"

  >
    <template v-slot:header-action>
      <!-- TOOLBAR -->
      <v-row v-if="devices.length > 0" class="ma-0" align="center">
        <IconButton
          class="mr-4"
          icon="mdi-download"
          style="border-radius: 50%; border: 1px solid #c0c0c0"
          @click="handleDownloadZipFile"
        />

        <TowerSelector
          style="border-radius: 20px; border: 1px solid #d0d0d0; width: 16rem"
          class="mr-6"
          @update="updateTowerSelector"
        />
      </v-row>
    </template>

    <!-- CONTENT -->
    <template v-slot:content class="relative">
     
      <!-- IF AT LEAST ONE DEVICE EXIST, SHOW THIS -->
      <div v-if="devices.length > 0" class="body-content-height px-2 py-2">
        <!-- Live view gallery -->
        <TowerListViewGallery
          v-if="!isRefreshData"
          :latest="selectedTower"
          @export:imageUrls="getImageUrls"
        />
      </div>

      <div 
        v-else style="height: 100%; width: 100%; position: relative;">
      <!-- IF NO DEVICES AT ALL, SHOW THIS -->
      <NoDataTemplate
        class="center-component"
        icon="mdi-alert-circle-outline"
        :text="alertAreaErrorSentence"
      />
      </div>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import {ref, onMounted, computed} from '@vue/composition-api';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {useStore, useApi, useRoute, useI18n} from '@/plugins/vueUseMethods';

import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
import TowerListViewGallery from '@/components/tools/project/alarm-areas/TowerListViewGallery.vue';

import IconButton from '@components/button/IconButton.vue';
import NoDataTemplate from '@components/page/NoDataTemplate.vue';

import TowerSelector from '@/components/tools/project/alarm-areas/TowerSelector.vue';

export default {
  name: 'ProjectViewAlarmAreas',
  components: {
    ProjectPageTemplate,
    TowerListViewGallery,
    IconButton,
    TowerSelector,
    NoDataTemplate,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const {translate} = useI18n();
    const imageUrls = ref(null);
    const isLoading = ref(true);
    const hasError = ref(false);

    function updateTowerSelector() {
      isRefreshData.value = true;

      this.$nextTick(() => {
        // Adding the component back in
        isRefreshData.value = false;
      });
    }

    // =======================================================================
    // Lifecycle hook
    // =======================================================================
    onMounted(async () => {
      _fetchProjectAlarmAreasData();
    });

    // =======================================================================
    // Handling Fetch and Refetch Data
    // =======================================================================
    const isRefreshData = ref(false);
    const hasArmingMaskData = ref(false);
    const armingMaskData = ref(null);
    const failedCallApi = ref(false);
    const devices = ref([]);

    async function _fetchProjectAlarmAreasData() {
      try {
        const result = await useApi().project.getArmingMaskDevices(
          route.params['projectId'],
        );

        devices.value = result.data.devices;

        if (devices.value.length > 0) {
          store.commit('updateProjectArmingMaskDevices', result.data.devices);
          store.commit(
            'updateProjectArmingMaskCurrentDevice',
            result.data.devices[0].arming_data,
          );
          armingMaskData.value = result.data.devices[0].arming_data;
          hasArmingMaskData.value = true;
        }
      } catch (error) {
        // return;
        failedCallApi.value = true;
      } finally {
        isLoading.value = false;
      }
    }

    const selectedTower = computed(() => {
      if (store.getters.armingMaskCurrentDeviceData && isRefreshData) {
        // eslint-disable-next-line
        window.setTimeout(() => {
          isRefreshData.value = false;
        }, 250);
        return store.getters.armingMaskCurrentDeviceData;
      }
      return armingMaskData.value;
    });

    // ========================================================================
    // Handling download images as zip file
    // ========================================================================
    function getImageUrls(event) {
      imageUrls.value = event.value;
    }

    function handleDownloadZipFile() {
      window.setTimeout(() => {
        const zip = new JSZip();
        const imgZip = zip.folder('images');
        const fileExtension = 'png';
        const zipName = 'alarm-areas.zip';

        imageUrls.value.forEach((file) => {
            console.log(file)
          const base64Str = file.value.slice(22, file.length);
          const date = new Date(Date.now()).getTime();
          const savedName = `img-${date}.${fileExtension}`;

          imgZip.file(savedName, base64Str, {base64: true});
        });

        zip.generateAsync({type: 'blob', compression: "STORE" }).then((content) => {
          saveAs(content, zipName);
        });
      }, 300);
    }

    // ========================================================================
    // Sentence, show when have no devices (no Alert Area Setup)
    // ========================================================================
    const alertAreaErrorSentence = computed(() => {
      return translate('tools.project.arming.noAlertAreaSetup');
    });

    return {
      selectedTower,
      updateTowerSelector,
      isRefreshData,
      imageUrls,
      isLoading,
      hasError,
      getImageUrls,
      handleDownloadZipFile,
      armingMaskData,
      hasArmingMaskData,
      failedCallApi,
      devices,
      alertAreaErrorSentence,
    };
  },
};
</script>

<style scoped>
.center-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.body-content-height {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
}
</style>
