var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.devicesData.length > 0)?_c('div',[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('div',{staticStyle:{"width":"100%"},style:(_vm.loading ? 'pointer-events: none!important' : '')},[_c('v-menu',{attrs:{"nudge-top":"2","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tower-selector pl-2",staticStyle:{"width":"100%"}},'div',attrs,false),on),[_c('v-hover',{attrs:{"disabled":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"mt-2 d-flex justify-space-between align-center",staticStyle:{"width":"15rem"}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"font-weight-medium mx-2",staticStyle:{"font-size":"14px","color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(_vm._s(_vm.towerId)+" : ")]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14px","user-select":"none","color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(" "+_vm._s(_vm.devicesData[_vm.selectedTower].deviceName.replace( /-NVR/gm, '' ))+" ")])]),(_vm.loading)?_c('v-progress-circular',{staticClass:"mr-4",attrs:{"indeterminate":"","width":"2","size":"16","color":"primary"}}):_c('v-icon',{staticClass:"mr-2",class:hover ? 'primary--text' : '',attrs:{"size":"20"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)})],1)]}}],null,false,3931278184),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticStyle:{"width":"100%"},attrs:{"nav":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedTower),callback:function ($$v) {_vm.selectedTower=$$v},expression:"selectedTower"}},_vm._l((_vm.devicesData),function(value,index){return _c('v-list-item',{key:value.deviceId,attrs:{"dense":"","ripple":false}},[_c('v-list-item-title',{staticClass:"ml-1",class:_vm.selectedTower == index
                    ? 'primary--text font-weight-bold'
                    : '',staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(value.deviceName.replace(/-NVR/gm, ''))+" ")])],1)}),1)],1)],1)],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }